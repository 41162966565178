import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          //.get('/apps/customer/customers', { params: queryParams })
          .get(`/api/v1/Customer/search?Search=${queryParams.q}&CustomerTypes=2&CustomerTypes=3${queryParams.includeDealer ? '&CustomerTypes=4':''}&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
          //.get(`/api/v1/Customer/search?Search=${queryParams.q}&CustomerTypes=2&CustomerTypes=3&CustomerTypes=4&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
            //.get('/apps/customer/customers', { params: queryParams })
            .get(`/api/v1/Customer/searchall?Search=${queryParams.q}&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
            //.get(`/api/v1/Customer/search?Search=${queryParams.q}&CustomerTypes=2&CustomerTypes=3&CustomerTypes=4&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchCustomer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          //.get(`/apps/user/users/${id}`)
          .get(`/api/v1/Customer?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        console.log(`add`,customerData.customerData)
        let customer = customerData.customerData
        axios
          //.post('/apps/customer/customers', { customer: customerData })
          .post('/api/v1/Customer',  customer)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        
        let customer = customerData.customerData
        axios
          //.post('/apps/customer/customers', { customer: customerData })
          .put('/api/v1/Customer',  customer)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCustomer(ctx, {id}) {
      return new Promise((resolve, reject) => {
        console.log('ID STORE', id)              
        axios
          //.post('/apps/customer/customers', { customer: customerData })
          .delete(`/api/v1/Customer?Id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPlans(ctx, ) {
      return new Promise((resolve, reject) => {
        axios
          //.get('/apps/customer/customers', { params: queryParams })
          .get(`/api/v1/Customer/searchplans?Page=1&PerPage=5`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },// /api/v1/Customer/searchtype
    fetchTypeCustomer(ctx, ) {
      return new Promise((resolve, reject) => {
        axios
          //.get('/apps/customer/customers', { params: queryParams })
          .get(`/api/v1/Customer/searchtype`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
  },

}
