<template>
<div>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

        <div class="m-2">

            <!-- Table Top -->
            <b-row>

                <!-- Per Page -->
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <label>{{ $t('Show')}}</label>
                    <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                    <label>{{ $t('entries')}}</label>
                </b-col>
<!--              <b-col cols="12" md="4" class="">-->
<!--                <vue-autosuggest v-model="searchOwner" :suggestions="filteredOptions" :input-props="inputProps"-->
<!--                                 @selected="selectHandler" @input="onInputChange">-->
<!--                  <template slot-scope="{suggestion}">-->
<!--                    <span class="my-suggestion-item">{{ suggestion.item.name }}</span>-->
<!--                    <div v-show="suggestion.item.ownerName">-->
<!--                      <span class="text-muted">{{ $t('Belongs to: ') }}</span>-->
<!--                      <span class="font-weight-bold"> {{ suggestion.item.ownerName }} </span>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </vue-autosuggest>-->
<!--              </b-col>-->

                <!-- Search -->
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
                        <b-dropdown v-if="user.role == 'TRCKOWN' || user.role ==='SYSADMIN'" variant="primary">
                            <template #button-content>
                                {{ $t('Add Device')}}
                            </template>
                            <b-dropdown-item :to="{ name: 'register-device' }">{{ $t('Add Device')}}</b-dropdown-item>
                            <b-dropdown-item :to="{ name: 'device-bulk' }">{{ $t('Add Device Bulk')}}</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </b-col>
            </b-row>

        </div>

        <b-table ref="refDeviceListTable" class="position-relative" :items="fetchDevices" responsive :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty :empty-text="emptyText" :sort-desc.sync="isSortDirDesc">

          <!-- Column: Customer -->

          <template #head(lastConnectionTime)>
            {{ $t('Last Connection') }}
          </template>
          <template #cell(lastConnectionTime)="data">
            <div class="font-weight-bold d-block text-nowrap">
              {{ data.item.lastConnectionTime }}
            </div>
          </template>
          
            <!-- Column: Customer -->

            <template #head(customerName)>
                {{ $t('Customer')}}
            </template>
            <template #cell(customerName)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.customerName }}
                </div>
            </template>

            <!-- Column: Asset Identifier -->
            <template #head(assetIdentifier)>
                {{ $t('Asset Identifier')}}
            </template>
            <template #cell(assetIdentifier)="data">
                <div class="font-weight-bold d-block text-nowrap">
                  <b-link :to="{ name: 'register-asset', params: { id: data.item.assetId }}" target="_blank" class="font-weight-bold ">
                    <span class="text-uppercase">{{ data.item.assetIdentifier }}</span>
                  </b-link>
                </div>
            </template>

            <!-- Column: Modem Identifier -->
            <template #head(modemIdentifier)>
                {{ $t('Identifier')}}
            </template>
            <template #cell(modemIdentifier)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.modemIdentifier }}
                </div>
            </template>

                               

            <!-- Column: Mobile Sim Card -->
            <template #head(simCardNumber)>
                {{ $t('SIM Card Number')}}
            </template>
            <template #cell(simCardNumber)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.simCardNumber }}
                </div>
            </template>

            <!-- Column: Actions -->
            <template #head(actions)>
                {{ $t('Actions')}}
            </template>
            <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                    <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>

                    <b-dropdown-item v-if="user.role == 'TRCKOWN' || user.role ==='SYSADMIN'" :to="{ name: 'register-device', params: { id: data.item.modemId } }">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">{{ $t('Edit')}}</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="modalDelete(data.item.id)" v-if="user.role == 'TRCKOWN' || user.role ==='SYSADMIN' ">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">{{ $t('Delete')}}</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="!data.item.assetIdentifier  && (user.role == 'TRCKOWN' || user.role ==='SYSADMIN')" :to="{ name: 'install-device', params: { id: data.item.id } }">
                        <feather-icon icon="ShieldIcon" />
                        <span class="align-middle ml-50">{{ $t('Install')}}</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="(data.item.assetIdentifier) && (user.role == 'TRCKOWN' || user.role ==='SYSADMIN')" :to="{ name: 'uninstall-device', params: { id: data.item.id } }">
                        <feather-icon icon="ShieldOffIcon" />
                        <span class="align-middle ml-50">{{ $t('Uninstall')}}</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>

        </b-table>
        <div class="mx-2 mb-2">
            <b-row>

                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted">{{ $t('Showing')}} {{ dataMeta.from }} {{ $t('to')}} {{ dataMeta.to }} {{ $t('of')}} {{ dataMeta.of }} {{ $t('entries')}}</span>
                </b-col>
                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                    <b-pagination v-model="currentPage" :total-rows="totalDevices" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>

                </b-col>

            </b-row>
        </div>
    </b-card>
</div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import store from '@/store'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import {
    avatarText
} from '@core/utils/filter'
import deviceStoreModule from '../deviceStoreModule'
import customerStoreModule from '../../customer/customerStoreModule'
import useAllDevicesList from "@/views/main/device/device-all-search/useAllDevicesList";

export default {
    components: {

        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        VueAutosuggest,

        vSelect,
    },
    data() {
        return {
            emptyText: this.$t('No matching records found'),
            searchOwner: '',
            datasuggest: [],
            filteredOptions: [],
            inputProps: {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: this.$t('Search Owner'),
            },
            limit: 3,
            selected: null,
        }
    },
    methods:{

      selectHandler(option) {
        this.selected = option.item
        this.filteredOptions = []
        this.searchOwner = option.item.name
        this.customerId = option.item.id

        console.log(`option selecionado `, this.customerId)
      },

      onInputChange(text) {
        if (text === '' || text === undefined || text.length < 3) {
          this.customerId = ''
          return
        }

        store.dispatch('app-customer/fetchCustomers', {
          q: this.searchOwner,
          page: 1,
          perPage: 5

        })
            .then(response => {

              this.filteredOptions = [{
                data: response.data.data,
              }]

            })
            .catch(error => {

              console.log(error)

            })
      },

        modalDelete(myId) {
            this.$swal({
                title: this.$t('Are you sure?'),
                text: this.$t("You can't revert your action"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('No'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
                customClass: {
                    cancelButton: 'cancelBtn',
                    confirmButton: 'mr-1',
                }
            }).then((result) => {
                if (result.value) {
                    console.log('ID', myId)
                    this.deleteDevice(myId)

                } else {}
            })
        },

        deleteDevice(myId) {
            store.dispatch('app-device/deleteDevice', {
                    id: myId
                })
                .then(response => {
                    this.$swal(this.$t('Deleted'), this.$t('You successfully deleted'), 'success')
                        .then((result) => {
                            if (result.value) {
                                this.refetchData()

                            } else {}
                        })

                })
                .catch(error => {
                    this.$swal(this.$t('Error'), this.$t('An error occurred while trying to delete'), 'error')
                    console.log(error);
                })
        }

    },
    setup() {
      const DEVICE_APP_STORE_MODULE_NAME = 'app-device'
      const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

        // Register module
      if (!store.hasModule(DEVICE_APP_STORE_MODULE_NAME)) store.registerModule(DEVICE_APP_STORE_MODULE_NAME, deviceStoreModule)
      if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
          if (store.hasModule(DEVICE_APP_STORE_MODULE_NAME)) store.unregisterModule(DEVICE_APP_STORE_MODULE_NAME)
          if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
        })

        const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

        const {
            fetchDevices,
            tableColumns,
            perPage,
            currentPage,
            totalDevices,
            dataMeta,
            perPageOptions,
            searchQuery,
            ownerId,
            sortBy,
            isSortDirDesc,
            refDeviceListTable,
            refetchData,

        } = useAllDevicesList()

        return {

            // Sidebar

            fetchDevices,
            tableColumns,
            perPage,
            currentPage,
            totalDevices,
            dataMeta,
            perPageOptions,
            searchQuery,
            ownerId,
            sortBy,
            isSortDirDesc,
            refDeviceListTable,
            refetchData,

            // Filter
            avatarText,
            user

        }
    },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style><style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
